/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-empty-function */
import { AlertState, SeverityLevel, WS_CHANNEL_RECEIVED_ALERT_NOTIFICATION } from '@serverfarm/nocd-commons';
// @ts-ignore: No declarations available
import logdown from 'logdown';
import moment from 'moment';
// @ts-ignore: No declarations available
import { Maybe, Some, None } from 'monet';
import pluralize from 'pluralize';
import * as React from 'react';
// @ts-ignore
import { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { ClearButton, Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
// @ts-ignore
import { CSVLink } from 'react-csv';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
// @ts-ignore: No declarations available
import RSA from 'react-simple-auth';
import { Badge, Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Spinner, UncontrolledTooltip } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';
import io, { Socket } from 'socket.io-client';

import { incommand as incommandProvider } from '../../auth/providers';
import config from '../../config';
import { State } from '../../reducers';
import { doesTargetExist } from '../../services/utilities';
import { messageForUserReloadThePage, openAssetMaintenanceDialog, openAssetMappingDialog } from '../assets';
import { MonitoringSystemDeletingState, MonitoringSystemEditingState } from '../monitoring-systems';
import { showErrorNotification, showInfoNotification } from '../notification';
import { SitesListingState, Site } from '../redux/entities';
import { fetchSites, fetchSitesGroups } from '../sites';
import { UserState, CompaniesListingState, logoutUser } from '../user';
import { DateTimeRender } from '../utils';
import { getAcknowledgedColor, getBadgeColor, getInMaintenanceColor, getStateColor } from '../utils/colors';
import { DateRangeFilter, DateRange, DateRangeFn } from '../utils/dateRangeFilter';
import { ServerfarmTable } from '../utils/serverfarmTable';
import { TextFilter } from '../utils/textFilter';

import { openAlertNotificationsList, alertNotificationProcessNotification, ackNotification } from './data/alert-notifications';
import { openAlertWorkLog } from './data/alert-worklog';
import { setSelectedAlert } from './data/alerts';
import {
  fetchAlerts,
  updateAlertListingFilter,
  updateAlertListingSort,
  alertsProcessNotification,
  fetchExportedAlerts,
  resetExportedAlerts,
  openAlertStakeholderNotification,
  openAlertNocInCommandRequest,
  openAlertSourceInformation,
  trackAlert,
  unTrackAlert,
  getTotalTrackedAlerts,
  pinAlert,
  unPinAlert,
  updateAlertListingHiddenColumns,
  resetAlerts,
} from './data/alerts';
import { Alert, ListSort } from './entities';
import { AlertsListingState } from './redux/entities';

const logger = logdown('component:AlertsList');

class AlertIdFilter extends React.Component<Props & AlertListsProps & { id?: string }> {
  shouldComponentUpdate(nextProps: Props & AlertListsProps & { id?: string }) {
    const isIsInProgressChanged = nextProps.alertsListing.isInProgress !== this.props.alertsListing.isInProgress;
    const isTotalChanged = nextProps.alertsListing.total !== this.props.alertsListing.total;
    const shouldChange = isIsInProgressChanged || isTotalChanged;
    return shouldChange;
  }

  render() {
    const initialValue = this.props.alertsListing.filter.flatMap((filter: any) => Maybe.fromUndefined(filter.alertId)).getOrElse('');
    return (
      <TextFilter
        id={this.props.id}
        initialState={initialValue}
        placeholder=""
        isSearching={this.props.alertsListing.isInProgress}
        onChange={(val) => {
          const valueToSet = val === '' || val === undefined ? undefined : val;
          if (this.props.alertsListing.filter.exists((filter) => filter.alertId !== valueToSet)) {
            this.props.updateAlertListingFilter(
              this.props.alertsListing.filter.map((filter: any) => ({ ...filter, alertId: valueToSet })),
              // this.props.alertsListing.page,
              0,
              this.props.alertsListing.sizePerPage,
            );
          }
        }}
      />
    );
  }
}

const SiteColumnFilter = (props: Props & AlertListsProps) => (/*params: any*/) => {
  const selectedOption = props.alertsListing.filter
    .map((dsf): any[] => {
      let fullSites: any[] = [];
      if (dsf.sitesGroupId?.length) {
        fullSites = [
          ...fullSites,
          ...props.sitesGroupsListing.sitesGroups
            .flatMap((sitesGroups) => Maybe.fromUndefined(sitesGroups.filter((s) => dsf.sitesGroupId?.find((a) => a === s.id))))
            .getOrElse([]),
        ];
      }
      if (dsf.siteId?.length) {
        fullSites = [
          ...fullSites,
          ...props.sitesListing.sites
            .flatMap((sites: Site[]) => {
              return Maybe.fromUndefined(sites.filter((s) => dsf.siteId?.find((a) => a === s.id)));
            })
            .getOrElse([]),
        ];
      }
      return fullSites;
    })
    .orJust([]);
  // const selectedOption: any[] = [];
  const options = [...props.sitesListing.sites.getOrElse([]), ...props.sitesGroupsListing.sitesGroups.getOrElse([])];
  return (
    <div style={{ width: '180px' }}>
      <Typeahead
        className="unset-heigth"
        multiple={true}
        data-testid="data-source-filter-sites-groups"
        id="data-source-filter-sites-groups"
        onChange={(selected: any) => {
          const siteId: number[] = [];
          const sitesGroupId: number[] = [];
          let providers: string[] = [];
          if (selected && selected.length) {
            for (let i = 0; i < selected.length; i++) {
              if (selected[i].sites) {
                sitesGroupId.push(selected[i].id);
                //providers.push(selected[i].sites.map((s: Site) => s.monitorSiteId));
                providers = [...providers, ...selected[i].sites.map((s: Site) => s.monitorSiteId)];
              } else {
                siteId.push(selected[i].id);
                providers.push(selected[i].monitorSiteId);
              }
            }
          }
          const filter = props.alertsListing.filter.map((filter: any) => ({
            ...filter,
            sitesGroupId,
            siteId,
            providers,
          }));
          props.updateAlertListingFilter(
            filter,
            // props.alertsListing.page,
            0,
            props.alertsListing.sizePerPage,
          );
        }}
        options={options}
        emptyLabel="No Sites Groups found"
        defaultSelected={selectedOption}
        labelKey={(sg: any) => (sg && sg.sites ? `${sg.company.name} - ${sg.name}` : sg.name)}
        disabled={props.alertsListing.isInProgress}
        renderMenu={(results, menuProps) => (
          <Menu {...menuProps}>
            {results.map((result, index) => {
              const key = `data-source-filter-sites-groups-item-${index}`;
              const value = result && result.sites ? `${result.company.name} - ${result.name}` : result.name;
              return (
                <MenuItem key={key} option={result} position={index}>
                  <span className="rolling-text">{value}</span>
                </MenuItem>
              );
            })}
          </Menu>
        )}
      >
        {({
          selected,
        }: {
          selected: any[]; // Replace `any[]` with the appropriate type for the `selected` array
        }) =>
          !!selected.length && (
            <div className="rbt-aux">
              <ClearButton
                onClick={() => {
                  const siteId: number[] = [];
                  const sitesGroupId: number[] = [];
                  const providers: string[] = [];
                  const filter = props.alertsListing.filter.map((filter: any) => ({
                    ...filter,
                    sitesGroupId,
                    siteId,
                    providers,
                  }));
                  props.updateAlertListingFilter(filter, props.alertsListing.page, props.alertsListing.sizePerPage);
                }}
              />
            </div>
          )
        }
      </Typeahead>
    </div>
  );
};

class AssetFilter extends React.Component<Props & AlertListsProps & { id?: string }> {
  shouldComponentUpdate(nextProps: Props & AlertListsProps & { id?: string }) {
    const isIsInProgressChanged = nextProps.alertsListing.isInProgress !== this.props.alertsListing.isInProgress;
    const isTotalChanged = nextProps.alertsListing.total !== this.props.alertsListing.total;
    const shouldChange = isIsInProgressChanged || isTotalChanged;
    return shouldChange;
  }

  render() {
    const initialValue = this.props.alertsListing.filter.flatMap((filter: any) => Maybe.fromUndefined(filter.asset)).getOrElse('');
    return (
      <TextFilter
        id={this.props.id}
        placeholder={''}
        isSearching={this.props.alertsListing.isInProgress}
        initialState={initialValue}
        onChange={(val) => {
          /**
           * Only set value if its changed. Consider '' === undefined
           */
          const valueToSet = val === '' || val === undefined ? undefined : val;
          if (this.props.alertsListing.filter.exists((filter: any) => filter.asset !== valueToSet)) {
            this.props.updateAlertListingFilter(
              this.props.alertsListing.filter.map((filter: any) => ({ ...filter, asset: valueToSet })),
              // this.props.alertsListing.page,
              0,
              this.props.alertsListing.sizePerPage,
            );
          }
        }}
      />
    );
  }
}

class SourceFilter extends React.Component<Props & AlertListsProps & { id?: string }> {
  shouldComponentUpdate(nextProps: Props & AlertListsProps & { id?: string }) {
    const isIsInProgressChanged = nextProps.alertsListing.isInProgress !== this.props.alertsListing.isInProgress;
    const isTotalChanged = nextProps.alertsListing.total !== this.props.alertsListing.total;
    const shouldChange = isIsInProgressChanged || isTotalChanged;
    return shouldChange;
  }

  render() {
    const initialValue = this.props.alertsListing.filter.flatMap((filter: any) => Maybe.fromUndefined(filter.source)).getOrElse('');
    console.log('initialValue =>', initialValue);
    return (
      <TextFilter
        id={this.props.id}
        initialState={initialValue}
        placeholder={`Search ${this.props.alertsListing.total} ${pluralize('records', this.props.alertsListing.total)}...`}
        isSearching={this.props.alertsListing.isInProgress}
        onChange={(val) => {
          console.log('changed => ', val);
          /**
           * Only set value if its changed. Consider '' === undefined
           */
          const valueToSet = val === '' || val === undefined ? undefined : val;
          if (this.props.alertsListing.filter.exists((filter: any) => filter.source !== valueToSet)) {
            this.props.updateAlertListingFilter(
              this.props.alertsListing.filter.map((filter: any) => ({ ...filter, source: valueToSet })),
              // this.props.alertsListing.page,
              0,
              this.props.alertsListing.sizePerPage,
            );
          }
        }}
      />
    );
  }
}

const UpdatedUtcDateRangeFilter = (props: Props & { alertsListing: AlertsListingState }) => (
  <DateRangeFilter
    id="date-range-updated"
    showErrorNotification={props.showErrorNotification}
    value={props.alertsListing.filter.flatMap((filter) => filter.updatedAt)}
    onReset={() => {
      props.updateAlertListingFilter(
        props.alertsListing.filter.map((filter: any) => ({
          ...filter,
          updatedAt: None(),
        })),
        // props.alertsListing.page,
        0,
        props.alertsListing.sizePerPage,
      );
    }}
    onSave={(range: DateRange | DateRangeFn) => {
      props.updateAlertListingFilter(
        props.alertsListing.filter.map((filter: any) => ({
          ...filter,
          updatedAt: Some(range),
        })),
        // props.alertsListing.page,
        0,
        props.alertsListing.sizePerPage,
      );
    }}
    disabled={props.alertsListing.isInProgress}
  />
);

const StateColumnFilter = (props: Props & AlertListsProps) => (/*params: any*/) => {
  const selectedState = props.alertsListing.filter
    .map((dsf) => {
      return dsf.state ? [dsf.state] : [];
    })
    .orJust([]);
  return (
    <Typeahead
      multiple={true}
      data-testid="alerts-filter-state"
      id="alerts-filter-state"
      onChange={(selected) => {
        const filter = props.alertsListing.filter.map((filter: any) => ({
          ...filter,
          state: selected.length > 1 ? selected[1] : selected[0],
        }));
        props.updateAlertListingFilter(
          filter,
          // props.alertsListing.page,
          0,
          props.alertsListing.sizePerPage,
        );
      }}
      filterBy={() => {
        return true;
      }}
      options={Object.values(AlertState).filter((value) => {
        return !value.includes('ACKNOWLEDGED');
      })}
      // placeholder="Select a state"
      selected={selectedState}
      disabled={props.alertsListing.isInProgress}
      clearButton={false}
      renderMenu={(results, menuProps) => (
        <Menu {...menuProps}>
          {results.map((result, index) => {
            const key = `alert-filter-state-item-${index}`;
            return (
              <MenuItem key={key} option={result} position={index}>
                <span className="rolling-text">{result}</span>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    >
      {({
        selected,
      }: {
        selected: any[]; // Replace `any[]` with the appropriate type for the `selected` array
      }) =>
        !!selected.length && (
          <div className="rbt-aux">
            <ClearButton
              onClick={() => {
                const state = '';
                const filter = props.alertsListing.filter.map((filter: any) => ({
                  ...filter,
                  state,
                }));
                props.updateAlertListingFilter(
                  filter,
                  // props.alertsListing.page,
                  0,
                  props.alertsListing.sizePerPage,
                );
              }}
            />
          </div>
        )
      }
    </Typeahead>
  );
};

const SeverityLevelColumnFilter = (props: Props & AlertListsProps) => (/*params: any*/) => {
  const selectedSeverityLevels = props.alertsListing.filter
    .map((dsf: any) => {
      return dsf.severityLevel !== undefined ? (typeof dsf.severityLevel != 'string' ? dsf.severityLevel : [dsf.severityLevel]) : [];
    })
    .orJust([]);

  const map: { id: number; name: string }[] = [];

  for (const n in SeverityLevel) {
    if (typeof SeverityLevel[n] === 'number') {
      // @ts-ignore
      map.push({ id: SeverityLevel[n], name: n });
    }
  }

  return (
    <Typeahead
      multiple={true}
      data-testid="alerts-filter-severity-level"
      id="alerts-filter-severity-level"
      onChange={(selected) => {
        const filter = props.alertsListing.filter.map((filter: any) => ({
          ...filter,
          //severityLevel: selected.length > 1 ? selected[1] : selected[0],
          severityLevel: [...new Set(selected)],
        }));
        props.updateAlertListingFilter(
          filter,
          // props.alertsListing.page,
          0,
          props.alertsListing.sizePerPage,
        );
      }}
      options={map.map((v) => v.name)}
      filterBy={() => {
        return true;
      }}
      // placeholder="Select a Severity Level"
      selected={selectedSeverityLevels}
      disabled={props.alertsListing.isInProgress}
      clearButton={false}
      renderMenu={(items, menuProps) => (
        <Menu {...menuProps}>
          {items.map((item, index) => {
            const key = `alert-filter-severity-level-item-${index}`;
            return (
              <MenuItem key={key} option={item} position={index}>
                <span className="rolling-text">{index}</span>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    >
      {({
        selected,
      }: {
        selected: any[]; // Replace `any[]` with the appropriate type for the `selected` array
      }) =>
        !!selected.length && (
          <div className="rbt-aux">
            <ClearButton
              onClick={() => {
                const severityLevel: string[] = [];
                const filter = props.alertsListing.filter.map((filter: any) => ({
                  ...filter,
                  severityLevel,
                }));
                props.updateAlertListingFilter(
                  filter,
                  //  props.alertsListing.page,
                  0,
                  props.alertsListing.sizePerPage,
                );
              }}
            />
          </div>
        )
      }
    </Typeahead>
  );
};

const IsInMaintenanceColumnFilter = (props: Props & AlertListsProps) => (/*params: any*/) => {
  const selectedIsInMaintenance = props.alertsListing.filter
    .map((dsf) => {
      return dsf.isInMaintenance ? dsf.isInMaintenance : 'Undefined';
    })
    .orJust('Undefined');
  return (
    <Label className="mt-1">
      <span
        className="clickable"
        onClick={() => {
          const filter = props.alertsListing.filter.map((filter: any) => ({
            ...filter,
            isInMaintenance:
              'Yes' === selectedIsInMaintenance
                ? 'No'
                : 'No' === selectedIsInMaintenance
                ? 'Undefined'
                : 'Undefined' === selectedIsInMaintenance
                ? 'Yes'
                : undefined,
          }));
          props.updateAlertListingFilter(filter, props.alertsListing.page, props.alertsListing.sizePerPage);
        }}
      >
        {selectedIsInMaintenance === 'Undefined' ? 'Yes/No' : selectedIsInMaintenance}
      </span>
    </Label>
  );
};

const AcknowledgedFilter = (props: Props & AlertListsProps) => (/*params: any*/) => {
  // debug();
  const selectedAcknowledged = props.alertsListing.filter
    .map((dsf) => {
      return dsf.acknowledged ? dsf.acknowledged : 'Undefined';
    })
    .orJust('Undefined');
  return (
    <Label className="mt-1">
      <span
        className="clickable"
        onClick={() => {
          const filter = props.alertsListing.filter.map((filter: any) => ({
            ...filter,
            acknowledged:
              'Yes' === selectedAcknowledged
                ? 'No'
                : 'No' === selectedAcknowledged
                ? 'Undefined'
                : 'Undefined' === selectedAcknowledged
                ? 'Yes'
                : undefined,
          }));
          props.updateAlertListingFilter(
            filter,
            // props.alertsListing.page,
            0,
            props.alertsListing.sizePerPage,
          );
        }}
      >
        {selectedAcknowledged === 'Undefined' ? 'Yes/No' : selectedAcknowledged}
      </span>
    </Label>
  );
};

class AcknowledgedByFilter extends React.Component<Props & AlertListsProps & { id?: string }> {
  shouldComponentUpdate(nextProps: Props & AlertListsProps & { id?: string }) {
    const isIsInProgressChanged = nextProps.alertsListing.isInProgress !== this.props.alertsListing.isInProgress;
    const isTotalChanged = nextProps.alertsListing.total !== this.props.alertsListing.total;
    const shouldChange = isIsInProgressChanged || isTotalChanged;
    return shouldChange;
  }

  render() {
    return (
      <TextFilter
        id={this.props.id}
        placeholder=""
        isSearching={this.props.alertsListing.isInProgress}
        onChange={(val) => {
          /**
           * Only set value if its changed. Consider '' === undefined
           */
          const valueToSet = val === '' || val === undefined ? undefined : val;
          if (this.props.alertsListing.filter.exists((filter: any) => filter.acknowledgedBy !== valueToSet)) {
            this.props.updateAlertListingFilter(
              this.props.alertsListing.filter.map((filter: any) => ({ ...filter, acknowledgedBy: valueToSet })),
              0,
              // this.props.alertsListing.page,
              this.props.alertsListing.sizePerPage,
            );
          }
        }}
      />
    );
  }
}

const WorkLogEntriesCountColumnFilter = (props: Props & AlertListsProps) => (/*params: any*/) => {
  const options = [
    { id: 1, label: 'No entries' },
    { id: 2, label: '> 0 entries' },
    { id: 3, label: 'Site Not Aware' },
  ];
  const selectedFilterElements = props.alertsListing.filter
    .map((dsf) => {
      return dsf.hasWorkLogEntries !== undefined
        ? [dsf.hasWorkLogEntries ? options[1] : options[0]]
        : dsf.isSiteNotAware === true
        ? [options[2]]
        : [];
    })
    .orJust([]);
  return (
    <Typeahead
      multiple={true}
      data-testid="work-log-entries-count-filter"
      id="work-log-entries-count-filter"
      onChange={(selected) => {
        const filter = props.alertsListing.filter.map((filter) => ({
          ...filter,
          hasWorkLogEntries:
            selected.length > 1
              ? selected && selected[1]
                ? (selected[1] as any).id === 2
                  ? true
                  : (selected[1] as any).id === 1
                  ? false
                  : undefined
                : undefined
              : selected && selected[0]
              ? (selected[0] as any).id === 2
                ? true
                : (selected[0] as any).id === 1
                ? false
                : undefined
              : undefined, //selected && selected[0] ? selected[0].id : undefined,
          isSiteNotAware:
            selected.length > 1
              ? selected && selected[1] && (selected[1] as any).id === 3
                ? true
                : undefined
              : selected && selected[0] && (selected[0] as any).id === 3
              ? true
              : undefined,
        }));
        props.updateAlertListingFilter(
          filter,
          // props.alertsListing.page,
          0,
          props.alertsListing.sizePerPage,
        );
      }}
      filterBy={() => {
        return true;
      }}
      options={options}
      // emptyLabel="No Sites found"
      // placeholder="Select an option"
      defaultSelected={selectedFilterElements}
      disabled={props.alertsListing.isInProgress}
      clearButton={false}
      renderMenu={(items, menuProps) => (
        <Menu {...menuProps}>
          {items.map((item, index) => {
            const key = `alert-filter-work-log-item-${index}`;
            return (
              <MenuItem key={key} option={item} position={index}>
                <span className="rolling-text">{item.label}</span>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    >
      {({
        selected,
      }: {
        selected: any[]; // Replace `any[]` with the appropriate type for the `selected` array
      }) =>
        !!selected.length && (
          <div className="rbt-aux">
            <ClearButton
              onClick={() => {
                const hasWorkLogEntries = undefined;
                const isSiteNotAware = undefined;
                const filter = props.alertsListing.filter.map((filter: any) => ({
                  ...filter,
                  hasWorkLogEntries,
                  isSiteNotAware,
                }));
                props.updateAlertListingFilter(filter, props.alertsListing.page, props.alertsListing.sizePerPage);
              }}
            />
          </div>
        )
      }
    </Typeahead>
  );
};

const ActionsDropdown = (props: Props & { id: string; row: Alert }) => {
  const [dropdownOpen, setOpen] = React.useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const { id, row } = props;

  return (
    <ButtonDropdown id={id} isOpen={dropdownOpen} toggle={toggle} color="info">
      <DropdownToggle caret className="btn-sm" color="info">
        <Icon.Play />
      </DropdownToggle>
      <DropdownMenu positionFixed={true} flip={true}>
        <DropdownItem
          onClick={() => {
            props.pinAlert(row);
            props.setSelectedAlert(row.id);
          }}
          hidden={row.isPinned}
        >
          Pin
        </DropdownItem>{' '}
        <DropdownItem
          onClick={() => {
            props.unPinAlert(row);
            props.setSelectedAlert(row.id);
          }}
          hidden={!row.isPinned}
        >
          Unpin
        </DropdownItem>{' '}
        <DropdownItem
          onClick={() => {
            props.trackAlert(row);
            props.setSelectedAlert(row.id);
          }}
          hidden={row.isTracked}
        >
          Start tracking
        </DropdownItem>{' '}
        <DropdownItem
          onClick={() => {
            props.unTrackAlert(row);
            props.setSelectedAlert(row.id);
          }}
          hidden={!row.isTracked}
        >
          Stop tracking
        </DropdownItem>{' '}
        <DropdownItem
          onClick={() => {
            props.openAssetMappingDialog(Some(row), Maybe.None());
            props.setSelectedAlert(row.id);
          }}
        >
          Map to Asset
        </DropdownItem>{' '}
        <DropdownItem
          onClick={() => {
            props.openAlertStakeholderNotification(row);
            props.setSelectedAlert(row.id);
          }}
        >
          Notify Stakeholder
        </DropdownItem>
        <DropdownItem
          style={{
            color: !row.lastNotification.acknowledged ? 'grey' : '',
          }}
          onClick={() => {
            props.openAlertNocInCommandRequest(row);
            props.setSelectedAlert(row.id);
          }}
          disabled={!row.lastNotification.acknowledged}
        >
          Create NOCD Alert Request
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            props.openAlertWorkLog(row);
            props.setSelectedAlert(row.id);
          }}
        >
          Show Work Log
        </DropdownItem>{' '}
        <DropdownItem
          onClick={() => {
            props.openAlertNotificationsList(row);
            props.setSelectedAlert(row.id);
          }}
        >
          Show History
        </DropdownItem>{' '}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

const ExportDropdown = (props: { disabled: boolean; exportData: any; onDropdownToggle: (isOpen: boolean) => void }) => {
  const [exportDropdownOpen, setExportDropdownOpen] = useState(false);

  const toggleExportDropdown = () => {
    const isOpen = !exportDropdownOpen;
    props.onDropdownToggle && props.onDropdownToggle(isOpen);
    setExportDropdownOpen(isOpen);
  };

  let csvLink: any;
  return (
    <ButtonDropdown isOpen={exportDropdownOpen && !!props.exportData && !!props.exportData.length} toggle={toggleExportDropdown}>
      <DropdownToggle caret className="btn-sm" disabled={props.disabled}>
        Export as {exportDropdownOpen && (!props.exportData || !props.exportData.length) ? <Spinner size="sm" /> : '...'}
      </DropdownToggle>
      <DropdownMenu>
        {/*<DropdownItem key="export-to-csv" onClick={() => csvLink.link.click()}>*/}
        <DropdownItem key="export-to-csv">
          <CSVLink data={props.exportData} filename="data.csv" className="hidden" ref={(r: any) => (csvLink = r)} target="_blank">
            CSV
          </CSVLink>
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

// const ShowHideColumnsDropdown = (props: {
//   columns: any[];
//   data: any;
//   disabled?: boolean;
//   onDropdownToggle?: (isOpen: boolean) => void;
//   onColumnVisibilityChange: (hiddenColumns: string[]) => void;
//   initialState: {
//     pageSize: number;
//     pageIndex: number;
//     sortBy?: SortProps[];
//     hiddenColumns?: string[];
//   };
// }) => {
//   const [showHideColumnsDropdownOpen, setShowHideColumnsDropdownOpen] = useState(false);
//   const {
//     allColumns,
//     state: { pageIndex, pageSize, sortBy, hiddenColumns },
//   } = useTable({
//     columns: props.columns,
//     data: props.data,
//     useControlledState: (state: any) => {
//       logger.log(`Controlled state`, state);
//       return {
//         ...state,
//       };
//     },
//   }) as any;

//   React.useEffect(() => {
//     props.onColumnVisibilityChange(hiddenColumns);
//   }, [hiddenColumns]);

//   const toggleDropdown = () => {
//     const isOpen = !showHideColumnsDropdownOpen;
//     props.onDropdownToggle && props.onDropdownToggle(isOpen);
//     setShowHideColumnsDropdownOpen(isOpen);
//   };

//   return (
//     <ButtonDropdown isOpen={showHideColumnsDropdownOpen} toggle={toggleDropdown}>
//       <DropdownToggle caret className="btn-sm" disabled={props.disabled}>
//         Show/Hide Columns
//       </DropdownToggle>
//       <DropdownMenu>
//         {allColumns
//           .filter((column: { disableVisibilityChange: any }) => !column.disableVisibilityChange)
//           .map((column: any) => {
//             const columnToggleHiddenProps = column.getToggleHiddenProps();
//             return (
//               <DropdownItem
//                 key={`column-to-show-hide-${column.id}`}
//                 onClick={() => {
//                   columnToggleHiddenProps.onChange({ target: { checked: !columnToggleHiddenProps.checked } });
//                 }}
//               >
//                 {column.isVisible ? 'Hide' : 'Show'} {typeof column.Header === 'function' ? column.Header() : column.Header}
//               </DropdownItem>
//             );
//           })}
//       </DropdownMenu>
//     </ButtonDropdown>
//   );
// };

const ActionsFormatter = (props: Props) => (row: Alert & any) => {
  const notification = props.alertNotificationsListing.notification.orUndefined();
  const isAcknowledged = row.lastNotification.acknowledged && row.lastNotification.acknowledged.length > 0;
  return (
    <span>
      <Button
        className="alert-acknowledge-btn"
        id={`alert-acknowledge-btn-${row.id}`}
        color="info"
        size="sm"
        onClick={() => {
          props.ackNotification(row.lastNotification, false);
        }}
        disabled={(props.alertNotificationsListing.isInProgress && notification && notification.id === row.lastNotification.id) || isAcknowledged}
      >
        <Spinner size="sm" hidden={!(props.alertNotificationsListing.isInProgress && notification && notification.id === row.lastNotification.id)} />
        <Icon.Check2 className="sm" />
      </Button>{' '}
      <Button
        className="maintenance-dialog-btn"
        id={`maintenance-dialog-btn-${row.id}`}
        color="info"
        size="sm"
        onClick={() => props.openAssetMaintenanceDialog(row.assets[0], row, true)}
        disabled={!row.assets.length || row.isInMaintenance}
      >
        <Icon.CalendarRange className="sm" />
      </Button>{' '}
      <ActionsDropdown {...props} id={`actions-${row.id}`} row={row} />
      {doesTargetExist(`alert-acknowledge-btn-${row.id}`) && (
        <UncontrolledTooltip target={`alert-acknowledge-btn-${row.id}`}>Acknowledge</UncontrolledTooltip>
      )}
      {doesTargetExist(`maintenance-dialog-btn-${row.id}`) && (
        <UncontrolledTooltip target={`maintenance-dialog-btn-${row.id}`}>Put on Maintenance with Acknowledge</UncontrolledTooltip>
      )}
      {doesTargetExist(`actions-${row.id}`) && (
        <UncontrolledTooltip target={`actions-${row.id}`}>Click to show available actions</UncontrolledTooltip>
      )}
      {row.isPinned && doesTargetExist(`pinned-${row.id}`) ? (
        <UncontrolledTooltip target={`pinned-${row.id}`}>This alert is pinned to stay on top</UncontrolledTooltip>
      ) : (
        ''
      )}
    </span>
  );
};

const columns = (props: Props & AlertListsProps) => [
  {
    id: 'id',
    Header: 'Alert Id',
    accessor: (row: Alert) => {
      const className = 'alerts-list-item-alert-id';
      return (
        <span id={`${className}-${row.id}`} className={className}>
          {row.id}
        </span>
      );
    },
    Filter: <AlertIdFilter {...props} id="alerts-header-alert-id-filter" />,
    disableSortBy: true,
  },
  {
    id: 'provider',
    width: '200px',
    Header: 'Site',
    accessor: (row: Alert) => {
      const className = 'alerts-list-item-site';
      return (
        <EllipsisText
          id={`${className}-${row.id}`}
          className={className}
          text={props.sitesListing.sites
            .flatMap((sites: Site[]) => Maybe.fromUndefined(sites.find((s) => row.provider.indexOf(`/${s.monitorSiteId}`) === 0)))
            .map((s: Site) => s.name)
            .getOrElse('N/A')}
          length={50}
        />
      );
    },
    Filter: SiteColumnFilter(props),
    isVisible: !props.alertsListing.hiddenColumns.includes('provider'),
  },
  {
    id: 'assets',
    width: '200px',
    Header: 'Assets',
    accessor: (row: Alert) => {
      const className = 'alerts-list-item-assets';
      return (
        <>
          <EllipsisText
            id={`${className}-${row.id}`}
            className={className}
            style={{
              color: row.isInMaintenance ? 'red' : 'black',
            }}
            text={row.assets.length ? row.assets.map((a) => a.name).join(', ') : ''}
            length={50}
          />
          {row.isInMaintenance && doesTargetExist(`${className}-${row.id}`) && (
            <UncontrolledTooltip target={`${className}-${row.id}`}>Asset is In Maintenance</UncontrolledTooltip>
          )}
        </>
      );
    },
    Filter: <AssetFilter {...props} id="alerts-header-asset-filter" />,
    isVisible: !props.alertsListing.hiddenColumns.includes('assets'),
  },
  {
    id: 'source',
    width: 'auto',
    Header: 'Source',
    accessor: (row: Alert) => {
      const className = 'alerts-list-item-source';
      return (
        <span>
          <EllipsisText id={`${className}-${row.id}`} className={className} text={row.source} length={120} />{' '}
          {row.isPinned ? <Icon.Pin id={`pinned-${row.id}`} style={{ color: 'rgb(220, 0, 0)' }} /> : ''}
          <Icon.QuestionCircle
            onClick={() => {
              props.openAlertSourceInformation(row);
            }}
            id={`${className}-${row.id}-question`}
            className="text-info"
            style={{ cursor: 'pointer' }}
          />
          {doesTargetExist(`${className}-${row.id}-question`) && (
            <UncontrolledTooltip target={`${className}-${row.id}-question`}>{row.lastNotification?.raw?.Message}</UncontrolledTooltip>
          )}
        </span>
      );
    },
    Filter: <SourceFilter {...props} id="alerts-header-source-filter" />,
    isVisible: !props.alertsListing.hiddenColumns.includes('source'),
  },
  {
    id: 'severityLevel',
    width: '80px',
    Header: 'Severity',
    accessor: (row: Alert) => {
      const className = 'alerts-list-item-severityLevel';
      return (
        <Badge
          style={{
            backgroundColor: getBadgeColor(row.severityLevel),
          }}
          id={`${className}-${row.id}`}
          className={className}
        >
          {row.severityLevel}
        </Badge>
      );
    },
    Filter: SeverityLevelColumnFilter(props),
    isVisible: !props.alertsListing.hiddenColumns.includes('severityLevel'),
  },
  {
    id: 'state',
    width: '80px',
    Header: 'State',
    accessor: (row: Alert) => {
      const className = 'alerts-list-item-state';
      return (
        <Badge
          style={{
            backgroundColor: getStateColor(row.state),
          }}
          id={`${className}-${row.id}`}
          className={className}
        >
          {row.state}
        </Badge>
      );
    },
    Filter: StateColumnFilter(props),
    isVisible: !props.alertsListing.hiddenColumns.includes('state'),
    disableSortBy: true,
  },
  {
    id: 'isInMaintenance',
    width: '100px',
    Header: () => {
      return (
        <span data-testid="alerts-header-is-in-maintenance" className="alerts-header-is-in-maintenance">
          In Maint.
        </span>
      );
    },
    accessor: (row: Alert) => {
      const className = 'alerts-list-item-is-in-maintenance';
      return (
        <Badge
          style={{
            backgroundColor: getInMaintenanceColor(row.isInMaintenance),
          }}
          id={`${className}-${row.id}`}
          className={className}
        >
          {row.isInMaintenance ? 'Yes' : 'No'}
        </Badge>
      );
    },
    Filter: IsInMaintenanceColumnFilter(props),
    isVisible: !props.alertsListing.hiddenColumns.includes('isInMaintenance'),
    disableSortBy: true,
  },
  {
    id: 'acknowledged',
    width: '130px',
    Header: () => {
      return (
        <span data-testid="alerts-header-acknowledged" className="alerts-header-acknowledged">
          Ack-ed (UTC)
        </span>
      );
    },
    accessor: (row: Alert) => {
      const className = 'alerts-list-item-acknowledged';
      return row.lastNotification.acknowledged ? (
        <DateTimeRender id={`${className}-${row.id}`} className={className} date={row.lastNotification.acknowledged.createdAt} isUtc={true} />
      ) : (
        <Badge
          style={{
            backgroundColor: getAcknowledgedColor(false),
          }}
          id={`${className}-${row.id}`}
          className={className}
        >
          No
        </Badge>
      );
    },
    Filter: AcknowledgedFilter(props),
    isVisible: !props.alertsListing.hiddenColumns.includes('acknowledged'),
    disableSortBy: true,
  },
  {
    id: 'acknowledgedBy',
    width: '120px',
    Header: () => {
      return (
        <span data-testid="alerts-header-acknowledged-by" className="alerts-header-acknowledged-by">
          Ack-ed By
        </span>
      );
    },
    accessor: (row: Alert) => {
      const className = 'alerts-list-item-acknowledged-by';
      return <span className={className}>{row.lastNotification.acknowledged ? row.lastNotification.acknowledged.userName : ''}</span>;
    },
    Filter: <AcknowledgedByFilter {...props} id="alerts-header-acknowledged-by-filter" />,
    isVisible: !props.alertsListing.hiddenColumns.includes('acknowledgedBy'),
    disableSortBy: true,
  },
  {
    id: 'notificationsCount',
    width: '80px',
    Header: 'Events',
    accessor: (row: Alert) => {
      const className = 'alerts-list-item-notifications-count';
      return <span className={className}>{row.notificationsCount} </span>;
    },
    isVisible: !props.alertsListing.hiddenColumns.includes('notificationsCount'),
    disableFilters: true,
  },
  {
    id: 'alertWorkLogsCount',
    width: '110px',
    Header: () => {
      return <span className="alerts-header-acknowledged">Work Logs</span>;
    },
    accessor: (row: Alert) => {
      let text = '';
      if (row.alertWorkLogsCountByType) {
        Object.keys(row.alertWorkLogsCountByType).forEach((key) => {
          switch (key) {
            case 'comment':
              text += `Comments: ${row.alertWorkLogsCountByType[key]}\n`;
              break;
            case 'pinning':
              text += `Pin Events: ${row.alertWorkLogsCountByType[key]}\n`;
              break;
            case 'tracking':
              text += `Track Events: ${row.alertWorkLogsCountByType[key]}\n`;
              break;
            case 'add_mapping':
              text += `Add Mapping Events: ${row.alertWorkLogsCountByType[key]}\n`;
              break;
            case 'stakeholder_notification':
              text += `Stakeholder Notifications Events: ${row.alertWorkLogsCountByType[key]}\n`;
              break;
            case 'nocd_alert_request_creation':
              text += `NOCD Alert Request create Events: ${row.alertWorkLogsCountByType[key]}\n`;
              break;
            default:
              throw new Error('Unknown Alert WorkLog count type');
          }
        });
      }

      const className = `alerts-list-item-work-log-entries-${row.id}`;
      return (
        <span>
          <span id={className}>{row.alertWorkLogsCount}</span>
          {text && doesTargetExist(`${className}`) ? <UncontrolledTooltip target={`${className}`}>{text}</UncontrolledTooltip> : ''}
        </span>
      );
    },
    Filter: WorkLogEntriesCountColumnFilter(props),
    isVisible: !props.alertsListing.hiddenColumns.includes('alertWorkLogsCount'),
    disableSortBy: true,
  },
  {
    id: 'updatedSiteLocal',
    width: '150px',
    Header: 'Updated (Site Local)',
    accessor: (row: Alert) => {
      const className = 'alerts-list-item-updated-site-local';
      return (
        <DateTimeRender
          id={`${className}-${row.id}`}
          className={className}
          date={row.updatedAt}
          timeZoneRef={props.sitesListing.sites
            .flatMap((sites: Site[]) => Maybe.fromUndefined(sites.find((s) => row.provider.indexOf(`/${s.monitorSiteId}`) === 0)))
            .map((s: Site) => s.timeZoneRef)
            .orUndefined()}
        />
      );
    },
    isVisible: !props.alertsListing.hiddenColumns.includes('updatedSiteLocal'),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    id: 'updatedUtc',
    width: '130px',
    Header: 'Updated (UTC)',
    accessor: (row: Alert) => {
      const className = 'alerts-list-item-updated-utc';
      return <DateTimeRender id={`${className}-${row.id}`} className={className} date={row.updatedAt} isUtc={true} />;
    },
    Filter: UpdatedUtcDateRangeFilter(props),
    isVisible: !props.alertsListing.hiddenColumns.includes('updatedUtc'),
  },
  {
    width: '160px',
    Header: 'Actions',
    accessor: (row: Alert) => ActionsFormatter(props)(row),
    disableVisibilityChange: true,
    disableFilters: true,
    disableSortBy: true,
  },
];

export interface AlertListsProps {
  user: UserState;
  alertsListing: AlertsListingState;
  companiesListing: CompaniesListingState;
  sitesListing: SitesListingState;
  monitoringSystemEditing: MonitoringSystemEditingState;
  monitoringSystemDeleting: MonitoringSystemDeletingState;
}

const crawfishUrl = new URL(config.services.crawfish.api.url);

class Component extends React.Component<Props & AlertListsProps, unknown> {
  protected socket?: Socket;
  protected socketReaction?: NodeJS.Timeout;
  componentWillUnmount() {
    if (this.socket) this.socket.disconnect();
  }

  refresh() {
    const updatedAt = this.props.alertsListing.filter.flatMap((filter: any) => Maybe.fromUndefined(filter.updatedAt)).getOrElse({ from: '', to: '' });
    const from = updatedAt.flatMap((date: any) => Maybe.fromUndefined(date.from).getOrElse(''));
    const to = updatedAt.flatMap((date: any) => Maybe.fromUndefined(date.to).getOrElse(''));

    const today = moment().startOf('day');
    const toDate = moment(to || new Date())
      .startOf('day')
      .isSame(today)
      ? moment().toDate()
      : to;

    const filter = this.props.alertsListing.filter.map((filter: any) => ({
      ...filter,
      updatedAt: Some({ from, to: toDate }),
    }));

    this.props.fetchSitesGroups();
    this.props.fetchSites();
    this.props.fetchAlerts(filter, this.props.alertsListing.page, this.props.alertsListing.sizePerPage, this.props.alertsListing.sort);
    this.props.getTotalTrackedAlerts();
  }

  componentDidMount() {
    this.props.setSelectedAlert('');
    this.refresh();
    // this.props.updateAlertListingFilter(filter, this.props.alertsListing.page, this.props.alertsListing.sizePerPage);
    try {
      this.socket = io(crawfishUrl.host, {
        path: `${crawfishUrl.pathname.replace(/\/$/, '')}/socket.io`,
        transports: ['websocket', 'polling'],
        // upgrade: true,
        rememberUpgrade: true,
        query: {
          token: RSA.getAccessToken(incommandProvider, config.oauth.client.url),
        },
      });
      this.socket?.on(WS_CHANNEL_RECEIVED_ALERT_NOTIFICATION, (serializedAlertNotification: string) => {
        logger.log(`Received serialized alert notification ${serializedAlertNotification}`);

        if (this.socketReaction) {
          clearTimeout(this.socketReaction);
        }

        this.socketReaction = setTimeout(() => {
          this.refresh();
        }, 100);
      });
    } catch (error) {
      this.props.messageForUserReloadThePage();
      this.props.logoutUser();
    }
  }

  componentDidUpdate(prevProps: Props & AlertListsProps) {
    const isFilterUpdated = !prevProps.alertsListing.filter.equals(this.props.alertsListing.filter);
    const isSortingUpdated = !prevProps.alertsListing.sort.equals(this.props.alertsListing.sort);
    const isPageSizeUpdated = !(prevProps.alertsListing.sizePerPage === this.props.alertsListing.sizePerPage);
    const isPageChanged = !(prevProps.alertsListing.page === this.props.alertsListing.page);
    const isAssetMappingDialogClosed = prevProps.assetMappingDialog.isOpen && !this.props.assetMappingDialog.isOpen;
    if (isSortingUpdated || isFilterUpdated || isPageSizeUpdated || isPageChanged || isAssetMappingDialogClosed) {
      this.refresh();
    }
  }

  public render() {
    const pageCount = Math.floor(this.props.alertsListing.total / this.props.alertsListing.sizePerPage) + 1;
    // const {
    //   allColumns,
    //   state: { pageIndex, pageSize, sortBy, hiddenColumns },
    // } = useTable({ columns: props.columns, data: props.data }) as any;
    //
    // React.useEffect(() => {
    //   this.props.onColumnVisibilityChange(hiddenColumns);
    // }, [hiddenColumns]);
    return (
      <div className="content">
        <ExportDropdown
          disabled={false}
          exportData={this.props.alertsListing.exportedAlerts
            .map((alerts) => {
              return alerts.map((alert) => {
                return {
                  'Alert Id': alert.id,
                  Site: this.props.sitesListing.sites
                    .flatMap((sites: Site[]) => Maybe.fromUndefined(sites.find((s) => alert.provider.indexOf(`/${s.monitorSiteId}`) === 0)))
                    .map((s: Site) => s.name)
                    .getOrElse('N/A'),
                  Assets: alert.assets.map((asset) => asset.name),
                  Source: alert.source,
                  'Severity Level': alert.severityLevel,
                  State: alert.state,
                  'In Maintenance?': (alert.isInMaintenance && 'Yes') || 'No',
                  'Acknowledged?':
                    (alert.lastNotification.acknowledged &&
                      alert.lastNotification.acknowledged.createdAt &&
                      new Date(alert.lastNotification.acknowledged.createdAt).toUTCString()) ||
                    '',
                  'Acknowledged By': (alert.lastNotification.acknowledged && alert.lastNotification.acknowledged.userName) || '',
                  'Events Count': alert.notificationsCount,
                  'WorkLogs Count': alert.alertWorkLogsCount,
                  'WorkLogs Comments': (alert.alertWorkLogs && alert.alertWorkLogs.map((a: any) => a.workLog).join(', \n')) || '',
                  'Updated (UTC)': alert.updatedAt.toUTCString(),
                };
              });
            })
            .orJust([])}
          onDropdownToggle={(isOpen) => {
            if (isOpen) {
              this.props.fetchExportedAlerts(this.props.alertsListing.filter, this.props.alertsListing.sort);
            } else {
              this.props.resetExportedAlerts();
            }
          }}
        />
        {/*<ShowHideColumnsDropdown*/}
        {/*  // disabled={props.isLoading}*/}
        {/*  columns={columns(this.props)}*/}
        {/*  data={this.props.alertsListing.alerts.orJust([])}*/}
        {/*  onColumnVisibilityChange={(hiddenColumns) => {*/}
        {/*    if (!hiddenColumns.length) {*/}
        {/*      return;*/}
        {/*    }*/}
        {/*    if (this.props.alertsListing.hiddenColumns.length !== hiddenColumns.length) {*/}
        {/*      // if (hiddenColumns.length) {*/}
        {/*      console.log('hiddenColumns =>', hiddenColumns);*/}
        {/*      this.props.updateAlertListingHiddenColumns(hiddenColumns);*/}
        {/*      // columns.forEach((column) => {});*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  onDropdownToggle={(isOpen) => {*/}
        {/*    if (isOpen) {*/}
        {/*      this.props.fetchExportedAlerts(this.props.alertsListing.filter, this.props.alertsListing.sort);*/}
        {/*    } else {*/}
        {/*      this.props.resetExportedAlerts();*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  initialState={{*/}
        {/*    pageIndex: this.props.alertsListing.page,*/}
        {/*    pageSize: this.props.alertsListing.sizePerPage,*/}
        {/*    sortBy: this.props.alertsListing.sort.map((s) => [{ id: s.field, desc: s.order === 'desc' }]).orJust([]),*/}
        {/*    hiddenColumns: this.props.alertsListing.hiddenColumns,*/}
        {/*  }}*/}
        {/*/>*/}
        <Button className="btn-sm reset-filter" onClick={() => this.props.resetAlerts()} disabled={this.props.alertsListing.isInProgress}>
          Reset Filters
        </Button>{' '}
        <ServerfarmTable
          columns={columns(this.props)}
          data={this.props.alertsListing.alerts.orJust([])}
          exportData={this.props.alertsListing.exportedAlerts
            .map((alerts) => {
              return alerts.map((alert) => {
                return {
                  Site: this.props.sitesListing.sites
                    .flatMap((sites: Site[]) => Maybe.fromUndefined(sites.find((s) => alert.provider.indexOf(`/${s.monitorSiteId}`) === 0)))
                    .map((s: Site) => s.name)
                    .getOrElse('N/A'),
                  Assets: alert.assets.map((asset) => asset.name),
                  Source: alert.source,
                  'Severity Level': alert.severityLevel,
                  State: alert.state,
                  'In Maintenance?': (alert.isInMaintenance && 'Yes') || 'No',
                  'Acknowledged?': (alert.lastNotification.acknowledged && alert.lastNotification.acknowledged.createdAt) || '',
                  'Acknowledged By': (alert.lastNotification.acknowledged && alert.lastNotification.acknowledged.userName) || '',
                  'Events Count': alert.notificationsCount,
                  'Comments Count': alert.commentsCount,
                  Updated: alert.updatedAt,
                };
              });
            })
            .orJust([])}
          onPageChange={(pageIndex, pageSize) => {
            if (this.props.alertsListing.page !== pageIndex || this.props.alertsListing.sizePerPage !== pageSize) {
              this.props.updateAlertListingFilter(this.props.alertsListing.filter, pageIndex, pageSize);
            }
          }}
          onSortChange={(sortBy) => {
            const newSort: Maybe<ListSort> = sortBy.length > 0 ? Some({ field: sortBy[0].id, order: sortBy[0].desc ? 'desc' : 'asc' }) : None();
            // this.props.alertsListing.sort.equals(newSort);
            if (!this.props.alertsListing.sort.equals(newSort)) {
              this.props.updateAlertListingSort(newSort);
            }
          }}
          onColumnVisibilityChange={(hiddenColumns) => {
            this.props.updateAlertListingHiddenColumns(hiddenColumns);
          }}
          onReset={() => {
            this.props.resetAlerts();
          }}
          onDropdownToggle={(isOpen) => {
            if (isOpen) {
              this.props.fetchExportedAlerts(this.props.alertsListing.filter, this.props.alertsListing.sort);
            } else {
              this.props.resetExportedAlerts();
            }
          }}
          total={this.props.alertsListing.total}
          controlledPageCount={pageCount}
          initialState={{
            pageIndex: this.props.alertsListing.page,
            pageSize: this.props.alertsListing.sizePerPage,
            sortBy: this.props.alertsListing.sort.map((s) => [{ id: s.field, desc: s.order === 'desc' }]).orJust([]),
            hiddenColumns: this.props.alertsListing.hiddenColumns,
          }}
          isLoading={this.props.alertsListing.isInProgress}
          renderRow={(row: any) => {
            const classNames = [];
            if (row.original.isNew) {
              classNames.push('glow-row');
            } else if (row.original.isTracked) {
              classNames.push('table-warning');
            } else if (row.original.id == this.props.alertsListing.selectedAlert) {
              classNames.push('table-info');
            }
            return {
              className: classNames.join(' '),
            };
          }}
          showTopManageHiddenCols={true}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchAlerts,
      fetchExportedAlerts,
      trackAlert,
      unTrackAlert,
      getTotalTrackedAlerts,
      updateAlertListingFilter,
      updateAlertListingSort,
      updateAlertListingHiddenColumns,
      showInfoNotification,
      showErrorNotification,
      openAlertNotificationsList,
      alertsProcessNotification,
      alertNotificationProcessNotification,
      ackNotification,
      fetchSitesGroups,
      fetchSites,
      pinAlert,
      unPinAlert,
      openAssetMappingDialog,
      resetExportedAlerts,
      openAlertWorkLog,
      openAlertStakeholderNotification,
      openAlertNocInCommandRequest,
      openAlertSourceInformation,
      openAssetMaintenanceDialog,
      resetAlerts,
      logoutUser,
      messageForUserReloadThePage,
      setSelectedAlert,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  alertsListing: state.alertsListing,
  companiesListing: state.companiesListing,
  sitesGroupsListing: state.sitesGroupsListing,
  alertNotificationsListing: state.alertNotificationsListing,
  sitesListing: state.sitesListing,
  assetMappingDialog: state.assetMappingDialog,
  alertsTrackingTotalCount: state.alertsTrackingTotalCount,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
